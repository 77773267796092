@import '../../styles/vars';
@import '../../styles/mixins';
@import '../../styles/breakpoints';

.wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $color-white;

  .innerWrap {
    width: 320px;
    max-width: 320px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex: 1;
    padding: 16px 24px;

    @include tablet-up {
      width: 604px;
      max-width: 604px;
      padding: 16px 0;
    }

    @include desktop-up {
      width: 1093px;
      max-width: 1093px;
      padding: 16px 0;
    }

    .logo {
      margin-left: 0;
      height: 44px;
      width: 111px;
      min-height: 44px;
      min-width: 111px;
      align-self: stretch;
      background-image: url('/employer/assets/images/svg/fasttalent_by_lensa_44px_vertical.svg');
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
      background-origin: border-box;

      @include tablet-up {
        margin-left: 0;
        height: 78px;
        width: 196px;
        min-height: 78px;
        min-width: 196px;
        background-image: url('/employer/assets/images/svg/fasttalent_by_lensa_78px_vertical.svg');
      }

      @include desktop-up {
        margin-left: 26px;
        height: 72px;
        width: 292px;
        min-height: 72px;
        min-width: 292px;
        background-image: url('/employer/assets/images/svg/fasttalent_by_lensa_72px_horizontal.svg');
      }
    }

    .button {
      &--mobile {
        display: block;

        @include tablet-up {
          display: none;
        }
      }

      &--tabletup {
        display: none;

        @include tablet-up {
          display: block;
        }

        @include desktop-up {
          padding-right: 40px;
        }
      }
    }
  }
}
