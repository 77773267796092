@import '../../styles/vars';
@import '../../styles/mixins';
@import '../../styles/breakpoints';

.content {
  position: relative;
  display: inline-block;

  .text {
    position: absolute;
    width: 300px;
    bottom: 100%;
    z-index: 1;
    left: 50%;
    transform: translateX(-50%);
    padding: 8px 16px;
    border-radius: 20px;
    background-color: $color-coolgray80;
    color: white;

    display: none;
    opacity: 0;
    transition: opacity 0.2s;
  }

  &:hover {
    .text {
      display: block;
      opacity: 1;
      transition: opacity 0.2s;
    }
  }
}
