@import '../../styles/vars';
@import '../../styles/mixins';
@import '../../styles/breakpoints';

.content {
  background: $color-black;
  .wrap {
    margin: 0 auto;
    max-width: 272px;
    padding: 40px 0;

    @include tablet-up {
      max-width: 604px;
      padding: 40px 0;
    }

    @include desktop-up {
      max-width: 1040px;
      padding: 40px 0;
    }

    .links {
      margin-bottom: 40px;

      @include tablet-up {
        display: flex;
        flex-wrap: wrap;
      }

      .link {
        @include p6;
        color: $color-gray40;
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        margin-bottom: 20px;

        &:last-of-type {
          margin-bottom: 0;
        }

        @include tablet-up {
          display: inline-block;
          margin-bottom: 0;
          margin-right: 12px;
        }

        @include desktop-up {
          margin-right: 16px;
        }

        .icon-right {
          margin-left: 6px;
        }
      }

      .link--whitebg {
        @extend .link;
        background-color: $color-white;
        padding: 0.25rem 0.5rem;
        user-select: none;
        cursor: pointer;
      }

      .break {
        display: none;

        @include desktop-up {
          display: inline-block;
          flex-basis: 100%;
          width: 0;
        }
      }
    }

    .socials {
      margin-bottom: 24px;
      display: flex;

      .icon {
        margin-right: 4px;

        a {
          height: 32px;
          width: 32px;
          display: block;

          img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }

    .info {
      p {
        @include p6;
        color: $color-gray40;
        line-height: 18px;
        font-weight: 600;

        &:first-of-type {
          margin-bottom: 1px;

          @include tablet-up {
            margin-bottom: 0;
          }
        }

        @include tablet-up {
          display: inline-block;
        }
      }
    }
  }
}
