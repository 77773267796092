@import '../../../../styles/mixins';
@import '../../../../styles/breakpoints';

.card {
  width: 264px;
  margin: 0 auto;
  box-shadow: 0px 8px 32px 0px #52525226;
  position: relative;
  padding: 24px;

  @include tablet-up {
    width: 606px;
    padding: 64px 72px 64px 72px;
  }

  @include desktop-up {
    width: 1157px;
    padding: 64px 72px 64px 72px;
  }

  .banner {
    position: absolute;
    top: 0;
    left: 0;

    img {
      position: absolute;

      &:nth-of-type(1) {
        top: 0;
        left: 0;
      }

      &:nth-of-type(2) {
        top: 17px;
        left: 20px;
        transform: rotateZ(1deg);
      }
    }
  }

  .content {
    display: flex;
    flex-direction: column;

    @include desktop-up {
      flex-direction: row;
    }

    .ebook-cover {
      margin-top: 64px;

      @include tablet-up {
        transform: translateX(10%);
      }

      @include desktop-up {
        margin-top: 0;
        flex: 0 0 50%;
        display: flex;
        justify-content: center;
        align-items: center;
      }

      img {
        width: 100%;
      }
    }

    .ebook-information {
      @include tablet-up {
        flex: 0 0 50%;
      }

      .title {
        @include h2;
        color: #2d3748;
        text-transform: capitalize;
        margin-bottom: 32px;

        @include tablet-up {
          margin-bottom: 24px;
        }

        .gradient-word {
          @include gradient-text2;
          position: relative;
          display: inline-block;

          .underline {
            position: absolute;

            width: 48px;
            height: 6px;
            background: red;
            bottom: 0;
            left: 0;
            transform: translateY(100%);
            background: $color-cyan60;
          }
        }
      }

      .description {
        margin-bottom: 40px;
        @include p1;

        .bold {
          font-weight: 700;
          font-family: 'SourceSansPro Bold', sans-serif;
        }

        .highlighted {
          font-weight: 700;
          font-family: 'SourceSansPro Bold', sans-serif;
          color: $color-blue500;
        }
      }

      .input {
        margin-bottom: 20px;
        width: 100%;
      }

      .button {
        margin-bottom: 16px;

        @include tablet-up {
          width: 100%;
        }
      }

      @mixin subscription-status-label {
        @include p8;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 16px;
      }

      .successful-subscription-label {
        @include subscription-status-label;

        color: $color-green40;

        span {
          font-family: 'SourceSansPro Bold', sans-serif;
          font-weight: 700;
        }
      }

      .unsuccessful-subscription-label {
        @include subscription-status-label;

        color: $color-red;
      }

      .disclaimer {
        @include p8;
      }
    }
  }
}

.separator {
  height: 0px;

  border: 1px solid $color-coolgray20;
  margin: 64px auto 64px auto;

  width: 300px;

  @include tablet-up {
    width: 606px;
  }

  @include desktop-up {
    max-width: 1120px;
    width: 78vw;
  }
}
