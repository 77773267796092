@import '../../styles/vars';
@import '../../styles/mixins';
@import '../../styles/breakpoints';

.content {
  width: 280px;
  margin: 64px auto 70px auto;
  position: relative;

  @include tablet-up {
    width: 606px;
    margin: 75px auto 93px auto;
  }

  @include desktop-up {
    width: 880px;
    margin: 54px auto 93px auto;
  }

  .list {
    display: flex;
    flex-direction: column;
    margin-bottom: 58px;
    justify-content: center;

    @include tablet-up {
      flex-direction: row;
      justify-content: space-between;
    }

    @include desktop-up {
      margin-bottom: 66px;
    }

    .list-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 40px;
      text-align: center;

      &:nth-of-type(3) {
        margin-bottom: 0;
      }

      @include tablet-up {
        margin-bottom: 0;
      }

      img {
        height: 86px;
        width: 86px;
        margin-bottom: 20px;
      }
      h3 {
        @include h3bold;
        margin-bottom: 8px;

        span {
          @include h3;
          @include gradient-text2;
        }
      }
      p {
        @include p2;
        color: $color-gray70;
      }
    }
  }
  .description {
    @include h5;
    color: $color-gray70;
    text-align: center;

    @include desktop-up {
      @include h4;

      color: $color-gray70;
    }
  }
}
