@import '../../styles/vars';
@import '../../styles/mixins';
@import '../../styles/breakpoints';

.content {
  margin: 0 auto;
  max-width: 261px;
  padding: 64px 0 48px 0;
  background: white;

  @include tablet-up {
    max-width: 604px;
    padding: 10px 0 96px 0;
  }

  @include desktop-up {
    padding: 0 5px 0 26.5px;
    max-width: 1093px;
    display: flex;
    flex-direction: row;
  }

  .intro {
    padding-bottom: 56px;

    @include tablet-up {
      padding: 0 49px 68px 28px;
    }

    @include desktop-up {
      flex: 0 0 43%;
      padding: 0 99.5px 72px 0;
    }

    h2 {
      @include h2;
      margin-bottom: 8px;

      span {
        @include gradient-text2;
      }

      br {
        display: none;

        @include tablet-up {
          display: block;
        }
      }
    }

    .line {
      height: 6px;
      width: 48px;
      background: $color-cyan60;
      margin-bottom: 32px;
    }

    p {
      @include p2;
      color: $color-gray70;

      br {
        display: none;

        @include desktop-up {
          display: block;
        }
      }
    }
  }

  .options {
    display: flex;
    flex-direction: column;

    @include tablet-up {
      flex-direction: row;
      justify-content: space-between;
    }

    @include desktop-up {
      flex: 1 0 auto;
      padding-top: 24px;
    }

    .option {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 40px;

      &:nth-of-type(3) {
        margin-bottom: 0;
      }

      @include tablet-up {
        margin-bottom: 0;
      }

      img {
        display: block;
        width: 86px;
        height: 86px;
        margin-bottom: 20px;
      }

      h6 {
        @include h6;
        text-align: center;
        margin-bottom: 8px;
      }

      p {
        @include p3;
        text-align: center;
        color: $color-gray70;
      }
    }
  }
}
