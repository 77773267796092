@import '../../styles/vars';
@import '../../styles/mixins';
@import '../../styles/breakpoints';

.content {
  @include desktop-up {
    display: flex;
    flex-direction: row-reverse;
  }

  .hero {
    height: 264px;
    position: relative;
    width: 100%;
    overflow: hidden;

    @include tablet-up {
      height: 452px;
    }

    @include desktop-up {
      height: 508px;
      flex: 0 0 50%;
    }

    .image {
      height: 100%;
      background-image: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0)),
        url('/employer/assets/images/webp/team_photo.webp');
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center center;

      @include desktop-up {
        background-image: url('/employer/assets/images/webp/team_photo.webp');
        background-position: right center;
      }
    }

    .caption {
      position: absolute;
      top: 16px;
      left: 27px;

      @include tablet-up {
        top: 20px;
        left: 72px;
      }

      @include desktop-up {
        display: none;
      }

      .title {
        @include p7;
        text-transform: uppercase;
        margin-bottom: 6px;
      }

      .logo {
        position: relative;
        display: block;
        height: 16px;
        width: 53.46px;

        @include tablet-up {
          height: 24px;
          width: 80.19px;
        }
      }
    }
  }

  .description {
    background-image: url('/employer/assets/images/svg/maze_pattern_320x320.svg');

    @include desktop-up {
      flex: 0 0 50%;
    }

    .description-content {
      margin: 0 auto;
      max-width: 265px;
      padding: 32px 0;

      @include tablet-up {
        max-width: 590px;
        padding: 48px 0 63px 0;
      }

      @include desktop-up {
        padding: 0;
        margin: 127.8px 120px 0 calc(50vw - 120px - 400px);
        max-width: unset;
        width: 400px;
      }

      .title {
        display: none;

        @include desktop-up {
          @include h6;
          margin-bottom: 12px;
          display: block;
        }
      }

      .logo {
        display: none;

        @include desktop-up {
          display: block;
          height: 24px;
          margin-bottom: 24px;
          width: 80.19px;
        }
      }

      .text {
        @include p2;
        color: $color-gray70;

        @include tablet-up {
          @include p1;
          color: $color-gray70;
        }
      }
    }
  }
}
