@import '../../styles/vars';
@import '../../styles/mixins';

%wrap {
  @include font-sfprodisplay;
  font-weight: 500;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  white-space: nowrap;
  border-width: 2px;
  border-style: solid;
  user-select: none;
  cursor: pointer;

  .icon {
    height: 24px;
    width: 24px;
    margin-left: 4px;
    color: $color-white;
  }
}

%contentEffects {
  color: $color-white;
  background-color: $color-cyan60;
  border-color: $color-cyan60;
  filter: drop-shadow(0px 8px 32px rgba(0, 83, 154, 0.25));
  box-shadow: 0px 0px 5px rgba(255, 255, 255, 0.01); // fixes Firefox glitch of drop-shadow (LENSA-19672)

  &:hover {
    background-color: $color-cyan60hover;
    border-color: $color-cyan60hover;
    box-shadow: 0px 8px 32px rgba(0, 83, 154, 0.25);
  }

  &:focus {
    background-color: $color-cyan60;
    border-color: $color-cyan70;
    filter: none;
  }

  &:active {
    background-color: $color-cyan80;
    border-color: $color-cyan80;
    filter: none;
  }
}

%headerEffects {
  color: $color-cyan60;
  background-color: $color-white;
  border-color: $color-cyan60;

  &:hover {
    color: $color-white;
    background-color: $color-cyan60;
    border-color: $color-cyan60;
    box-shadow: 0px 8px 32px rgba(0, 83, 154, 0.25);
  }

  &:focus {
    color: $color-white;
    background-color: $color-cyan80;
    border-color: $color-cyan80;
  }

  &:active {
    color: $color-cyan60;
    background-color: transparent;
    border-color: $color-cyan70;
  }
}

@mixin featured-background {
  background: linear-gradient(90deg, #0072c3 0%, #a56eff 95.83%);
}

.wrap {
  &--contentM {
    @extend %wrap;
    font-size: 16px;
    height: 48px;
    padding: 0 54px;
    border-radius: 24px;
    @extend %contentEffects;
  }

  &--contentL {
    @extend %wrap;
    font-size: 20px;
    height: 56px;
    padding: 0 62px;
    border-radius: 32px;
    @extend %contentEffects;
  }

  &--contentL-featured {
    @extend %wrap;
    font-size: 20px;
    height: 56px;
    padding: 0 62px;
    border-radius: 32px;
    border: none;
    @extend %contentEffects;
    @include featured-background;
  }

  &--headerM {
    @extend %wrap;
    font-size: 12px;
    height: 32px;
    padding: 0 14px;
    border-radius: 16px;
    @extend %headerEffects;
  }

  &--headerL {
    @extend %wrap;
    font-size: 14px;
    height: 40px;
    padding: 0 14px;
    border-radius: 24px;
    @extend %headerEffects;
  }

  &--pricing {
    @extend %wrap;
    font-size: 16px;
    height: 50px;
    padding: 0 54px;
    @extend %contentEffects;
    border: none;
    border-radius: 32px;
    background: $color-cyan60;
  }

  &--pricing-featured {
    @extend %wrap;
    font-size: 16px;
    height: 50px;
    padding: 0 54px;
    @extend %contentEffects;
    border: none;
    border-radius: 32px;
    @include featured-background;
    background: linear-gradient(90deg, #0072c3 0%, #a56eff 95.83%);
  }
}
