@import './_vars.scss';

@mixin h1 {
  font-size: 40px;
  font-family: 'Inter Black', sans-serif;
  font-weight: 900;
  line-height: 48px;
  color: $color-gray90;
}

@mixin h2 {
  font-size: 32px;
  font-family: 'Inter Black', sans-serif;
  font-weight: 900;
  line-height: 38.73px;
  color: $color-gray90;
}

@mixin h3 {
  font-size: 24px;
  font-family: 'Inter Black', sans-serif;
  font-weight: 900;
  line-height: 29.05px;
  color: $color-gray90;
}

@mixin h3bold {
  font-size: 24px;
  font-family: 'Inter Bold', sans-serif;
  line-height: 29.05px;
  font-weight: 700;
  color: $color-gray90;
}

@mixin h4 {
  font-size: 22px;
  font-family: 'Inter Light', sans-serif;
  font-weight: 300;
  line-height: 33px;
  color: $color-gray90;
}

@mixin h5 {
  font-size: 18px;
  font-family: 'Inter Light', sans-serif;
  font-weight: 300;
  line-height: 27px;
  color: $color-gray90;
}

@mixin h6 {
  font-size: 14px;
  font-family: 'Inter Bold', sans-serif;
  font-weight: 700;
  line-height: 16.94px;
  color: $color-gray90;
}

@mixin p1 {
  font-size: 18px;
  font-family: 'SourceSansPro Regular', sans-serif;
  font-weight: 400;
  line-height: 27px;
  color: $color-gray90;
}

@mixin p2 {
  font-size: 16px;
  font-family: 'SourceSansPro Regular', sans-serif;
  font-weight: 400;
  line-height: 24px;
  color: $color-gray90;
}

@mixin p3 {
  font-size: 14px;
  font-family: 'SourceSansPro Regular', sans-serif;
  font-weight: 400;
  line-height: 21px;
  color: $color-gray90;
}

@mixin p4 {
  font-size: 12px;
  font-family: 'SourceSansPro Bold', sans-serif;
  font-weight: 700;
  line-height: 15.08px;
  color: $color-gray90;
}

@mixin p5 {
  font-size: 12px;
  font-family: 'SourceSansPro Light', sans-serif;
  font-weight: 300;
  line-height: 15.08px;
  color: $color-gray90;
}

@mixin p6 {
  font-size: 12px;
  font-family: -apple-system, BlinkMacSystemFont, sans-serif;
}

@mixin p7 {
  font-size: 11px;
  font-family: -apple-system, BlinkMacSystemFont, sans-serif;
  font-weight: 400;
  line-height: 13.13px;
  color: $color-white;
}

@mixin p8 {
  color: #a0aec0;
  text-align: center;
  font-family: 'SourceSansPro Regular', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}

@mixin gradient-bg1 {
  background: linear-gradient(90deg, #0072c3 0%, #a56eff 95.83%);
}

@mixin gradient-text1 {
  background: linear-gradient(90deg, #0072c3 0%, #a56eff 95.83%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

@mixin font-sfprodisplay {
  font-family: -apple-system, BlinkMacSystemFont, sans-serif;
}

@mixin gradient-text2 {
  background: linear-gradient(90deg, $color-cyan60, #a56eff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

@mixin font-sfprodisplay {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen-Sans, Ubuntu, Cantarell, 'Helvetica Neue',
    sans-serif;
}

@mixin pricing-title {
  font-family: 'Inter ExtraBold', sans-serif;
  font-weight: 800;
  font-size: 28px;
  line-height: 34px;
  color: $color-gray90;
}
