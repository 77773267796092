@import '../../../../styles/vars';
@import '../../../../styles/mixins';
@import '../../../../styles/breakpoints';

.content {
  position: relative;
  flex: 0 0 264px;
  padding-top: 24px;
  scroll-snap-align: center;

  &-default {
    box-shadow: 0px 0px 15px rgba(19, 19, 19, 0.15);
  }

  &-featured {
    box-shadow: 0px 0px 15px 10px rgba(19, 19, 19, 0.1);
  }

  @include tablet-up {
    flex: 0 0 336px;
  }

  @include desktop-up {
    scroll-snap-align: unset;
  }

  @mixin horizontal-content-spacing {
    padding-left: 24px;
    padding-right: 24px;
  }

  .brand {
    @include p4;
    @include horizontal-content-spacing;
    color: $color-gray100;
  }

  .name {
    @include horizontal-content-spacing;
    @include pricing-title;
    padding-bottom: 12px;

    &-default {
      color: $color-cyan60;
    }

    &-featured {
      background: linear-gradient(90deg, #0072c3 0%, #a56eff 95.83%);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }

  .icon {
    position: absolute;
    top: 0;
    right: 0;
    border-top-right-radius: 24px;
  }

  .recommended-for {
    @include h6;
    padding-top: 4px;

    &-default {
      @include horizontal-content-spacing;
      color: $color-green40;
      margin-bottom: 16px;
    }

    &-featured {
      display: inline-block;
      color: $color-white;
      background: $color-orange30hover;
      margin-bottom: 12px;
      margin-left: 20px;
      padding-left: 12px;
      padding-right: 12px;
      padding-bottom: 4px;
      border-radius: 32px;
    }
  }

  .short-description {
    @include horizontal-content-spacing;
    @include h6;
    margin-bottom: 24px;
  }

  .separator {
    width: 100%;
    height: 0;
    border: 1px solid #f4f4f4;

    &-first {
      margin-bottom: 16px;
    }

    &-second {
      margin-bottom: 32px;
    }
  }

  .options {
    @include horizontal-content-spacing;
    display: flex;
    flex-direction: column;
    gap: 20px;
    height: 280px;
    @include tablet-up {
      height: 214px;
    }

    .option {
      display: flex;
      align-items: flex-start;
      gap: 8px;

      img {
        margin-top: 4px;
        width: 16px;
        height: 16px;
      }

      .text {
        @include p2;
        color: $color-gray70;
      }

      .tooltip {
        bottom: 8px;
        left: -6px;
      }
    }
  }

  @mixin highlight {
    height: 52px;
    background: rgba(237, 245, 255, 0.5);
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
  }

  @mixin highlight-featured {
    @include highlight;
    background: #edf5ff;
  }

  .payment-type {
    @include h6;
    &-default {
      @include highlight;
    }

    &-featured {
      @include highlight-featured;
    }
  }

  .checkout-options {
    .checkout-option-icons {
      display: flex;
      flex-direction: row;
      gap: 4px;
      justify-content: center;
      padding: 11.5px 0 8px 0;
    }

    p {
      @include p3;
      text-align: center;
      margin-bottom: 11.5px;
    }
  }

  .budget-type {
    @include p3;

    position: relative;
    margin-bottom: 24px;

    b {
      @include h6;
    }

    &-default {
      @include highlight;
    }

    &-featured {
      @include highlight-featured;
    }
  }

  .contact {
    @include h6;

    width: 232px;
    margin: 0 auto;
    text-align: center;
    margin-bottom: 16px;
  }

  .button-container {
    display: flex;
    justify-content: center;
    margin-bottom: 40px;

    .link {
      width: 100%;
      padding: 0 40px;
      .button {
        width: 100%;
      }
    }
  }
}
