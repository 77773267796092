@import './vars';

@mixin mobile-down {
  @media (max-width: ($break-s - 1px)) {
    @content;
  }
}
@mixin tablet-up {
  @media (min-width: $break-s) {
    @content;
  }
}
@mixin desktop-up {
  @media (min-width: $break-m) {
    @content;
  }
}
