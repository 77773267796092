@import '../../../../styles/vars';
@import '../../../../styles/mixins';
@import '../../../../styles/breakpoints';

.content {
  display: flex;
  flex-direction: row;
  gap: 16px;
  padding: 72px 16px 72px 16px;

  overflow-x: scroll;
  scroll-snap-type: x mandatory;

  -ms-overflow-style: none;
  scrollbar-width: none;
  &::-webkit-scrollbar {
    display: none;
  }

  @include tablet-up {
    gap: 40px;
    padding: 56px 40px 72px 40px;
  }

  @include desktop-up {
    overflow-x: unset;
    justify-content: space-between;
    width: 1088px;
    margin: 0 auto;
    padding: 56px 0 72px 0;

    overflow-x: unset;
    scroll-snap-type: unset;
  }
}

.discount-sticker {
  position: absolute;
  left: -25px;
  top: 50%;
  transform: translateY(-50%);
  filter: drop-shadow(0px 11px 45px rgba(162, 25, 31, 0.2)) drop-shadow(6px 10px 13px rgba(62, 62, 62, 0.06));

  @include mobile-down {
    width: 70px;
    left: 5px;
  }
}

.discount-budget-type {
  @include tablet-up {
    margin-left: 50px;
  }
}
