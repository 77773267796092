$break-s: 672px;
$break-m: 1056px;
$break-l: 1312px;

$base-space: 16px;

$space-025: $base-space * 0.25;
$space-050: $base-space * 0.5;
$space-075: $base-space * 0.75;
$space-0875: $base-space * 0.875;
$space-100: $base-space;
$space-1125: $base-space * 1.125;
$space-125: $base-space * 1.25;
$space-150: $base-space * 1.5;
$space-175: $base-space * 1.75;
$space-200: $base-space * 2;
$space-300: $base-space * 3;
$space-400: $base-space * 4;

$base-size: 16px;

$size-025: $base-size * 0.25;
$size-050: $base-size * 0.5;
$size-075: $base-size * 0.75;
$size-0875: $base-size * 0.875;
$size-100: $base-size;
$size-1125: $base-size * 1.125;
$size-125: $base-size * 1.25;
$size-150: $base-size * 1.5;
$size-175: $base-size * 1.75;
$size-200: $base-size * 2;
$size-300: $base-size * 3;

$color-white: #ffffff;
$color-black: #000000;

$color-gray10: #f4f4f4;
$color-gray40: #a8a8a8;
$color-gray70: #525252;
$color-gray80: #393939;
$color-gray90: #262626;
$color-gray100: #161616;

$color-cyan60: #0072c3;
$color-cyan60hover: #005fa3;
$color-cyan70: #00539a;
$color-cyan80: #003a6d;

$color-coolgray20: #dde1e6;
$color-coolgray80: #343a3f;

$color-green40: #42be65;

$color-red: #e60000;

$color-orange30hover: #ff9d57;

$color-blue500: #3182ce;
