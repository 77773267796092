@import '../../styles/vars';
@import '../../styles/mixins';
@import '../../styles/breakpoints';

.content {
  background-image: url('/employer/assets/images/svg/maze_pattern_320x320.svg');
  .wrap {
    padding-top: 39.5px;
    overflow: hidden;

    @include tablet-up {
      padding-top: 64px;
    }

    @include desktop-up {
      width: 100%;
      padding-top: 64px;

      display: grid;
      grid-template-columns: 1fr calc(403px) calc(1039px - 403px) 1fr;
    }

    .grid-spacer {
      display: none;

      @include desktop-up {
        display: block;
      }
    }

    .introduction {
      width: 271px;
      margin: 0 auto 23.5px auto;

      @include tablet-up {
        width: 606px;
        margin: 0 auto 32px auto;
      }

      @include desktop-up {
        width: unset;
        margin: unset;
      }

      h1 {
        @include h1;
        color: $color-gray80;
        margin-bottom: 8px;
        white-space: nowrap;

        span {
          @include gradient-text2;
        }
      }

      .line {
        height: 6px;
        width: 48px;
        background: $color-cyan60;
        margin-bottom: 28px;
      }

      p {
        @include p2;
        color: $color-gray80;
        margin-bottom: 28px;

        @include tablet-up {
          @include p1;
        }

        @include desktop-up {
          padding-right: 25px;
        }
      }

      .button {
        display: inline-flex;
        justify-content: flex-start;
      }
    }

    .image-container {
      height: 263px;
      width: 100%;
      position: relative;

      @include tablet-up {
        height: 310px;
      }

      @include desktop-up {
        height: 306px;
        margin-top: 52px;
      }

      .image {
        display: block;
        height: 100%;
        position: absolute;
        left: 50%;
        transform: translateX(-50%);

        @include desktop-up {
          transform: translateX(-50%);
        }
      }
    }
  }
}
