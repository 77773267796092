.input {
  border-radius: 6px;
  border: 1px solid #cbd5e0;
  background: #ffffff;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  height: 40px;
  padding: 8px 16px;
  justify-content: center;
  align-items: center;
  align-self: stretch;
}
