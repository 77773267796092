@import '../../styles/vars';
@import '../../styles/mixins';
@import '../../styles/breakpoints';

.content {
  position: relative;
  margin-top: 56px;

  .header {
    width: 264px;
    margin: 0 auto;

    @include tablet-up {
      width: 548px;
      margin: 0 auto;
    }

    @include desktop-up {
      width: 1040px;
      margin: 0 auto;
    }

    p {
      &:nth-of-type(1) {
        @include h2;
        background: linear-gradient(90deg, #0072c3 0%, #a56eff 95.83%);
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        margin-bottom: 1px;
      }
      &:nth-of-type(2) {
        @include h2;
      }
    }
    .separator {
      width: 48px;
      height: 6px;
      background: $color-cyan60;
    }
  }

  .background {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: linear-gradient(180deg, rgba(242, 244, 248, 0) 0%, #f2f4f8 100%);
    opacity: 0.5;
  }
}
