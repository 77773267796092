@import '../../styles/vars';
@import '../../styles/mixins';
@import '../../styles/breakpoints';

.content {
  background: $color-white;
  padding: 80px 58px 95px 58px;
  display: flex;
  flex-direction: column;

  @include tablet-up {
    padding: 80px 0 111px 0;
  }

  @include desktop-up {
    padding: 80px 0px 111px 0;
  }

  .title {
    @include h3bold;
    text-align: center;
    margin-bottom: 8px;
  }

  .subtitle {
    @include p2;
    text-align: center;
    margin-bottom: 20px;

    .break {
      display: inline-block;

      @include tablet-up {
        display: none;
      }
    }
  }

  .button {
    align-self: center;
    display: inline-flex;
    justify-content: center;
    margin: 0 auto;
    width: min-content;

    &--mobile {
      @include tablet-up {
        display: none;
      }
    }

    &--not-mobile {
      @include mobile-down {
        display: none;
      }
    }
  }
}
